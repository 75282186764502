import React from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"
import MultiVideoPlayer from "../components/MultiVideoPlayer"
import Resource from "../components/Resource"
import Icon from "../components/Icon"
import Educator_Guide from "../docs/CVS-BeVapeFree_VFT_Educator_Guide.pdf"
import TheCatchLogo from "../images/theme/logo-the-catch.svg"
import "../styles/vftpage.scss"
import { ImageVideoModal } from "../components/Modal"

const VirtualFieldTripPage = () => {
  return (
    <Layout>
      <SEO title="Clearing The Air" />
      {/***************  HERO ***************/}
      <Section className="hero">
        <Container>
          <Row>
            <Column span={7}>
              <h1>Virtual Field Trip</h1>
              <h3 className="hero__subhead">
                Join us for a real-world virtual learning opportunity to get the
                facts on the vaping epidemic.
              </h3>
            </Column>
          </Row>
        </Container>
      </Section>

      {/***************  Virtual Field Trip Full Width Video || VFT Day Of Launch ***************/}
      <Section className="white vftplayer intro">
        <Container>
          <Row>
            <Column span={12}>
            <ImageVideoModal
              imgPlaceholder="poster/cvs-vft-0.jpg"
              src="https://catch-public.s3.us-east-2.amazonaws.com/programs/CATCH-My-Breath/videos/virtual-field-trip-clearing-the-air.mp4"
            />
              {/* <MultiVideoPlayer
                videos={[
                  {
                    guidSrc: "ACB35A39-BAD9-44AF-B384-7D99B8C1982F",
                    title: "Clearing the Air Full VFT",
                    posterSrc: "poster/cvs-vft-0.jpg",
                  },
                  {
                    guidSrc: "9C6912FF-D74C-4CAB-95DC-2909258F35F2",
                    title: "Be Vape Free Virtual Field Trip Introduction",
                    posterSrc: "poster/cvs-vft-1.jpg",
                  },
                  {
                    guidSrc: "5C51AD78-C271-4087-867F-C6B12AD26039",
                    title: "Vaping Myths Exposed",
                    posterSrc: "poster/cvs-vft-2.jpg",
                  },
                  {
                    guidSrc: "084DC84E-6DB3-44B6-9470-27CACD2D5DCA",
                    title: "Vaping: Advertising and Social Media",
                    posterSrc: "poster/cvs-vft-3.jpg",
                  },
                  {
                    guidSrc: "0DBB81C2-5230-491B-8931-AAC91C6875BB",
                    title: "Vaping and Peer Pressure",
                    posterSrc: "poster/cvs-vft-4.jpg",
                  },
                ]}
              /> */}
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <h2>
                Clearing the Air: A <strong>Be Vape Free</strong> Virtual Field
                Trip
              </h2>
              <p>
                Join us for a real-world virtual learning opportunity to get the
                facts on the vaping epidemic. Students will gain powerful
                insights as several teenagers share their firsthand experiences
                of successfully avoiding e-cigarettes, learn how to think
                critically about everyday influences like advertising and social
                media, pick up effective refusal skills and debunk common myths
                with a health professional. Be sure to share this important
                virtual learning experience with your students, as those who use
                e-cigarettes are at increased risk of complications from
                respiratory illnesses like COVID-19!
              </p>
            </Column>
            <Column span={6}>
              <div className="socialcard">
                <div className="socialcard__left">
                  <Icon name="twitter" />
                </div>
                <div className="socialcard__right">
                  <h2>#BeVapeFree</h2>
                  <a
                    href="https://twitter.com/hashtag/BeVapeFree"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="socialcard__right-link"
                  >
                    View conversation on Twitter
                    <Icon name="externallink" />
                  </a>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      {/***************  VFT Activity Section ***************/}
      <Section className="yellow-pattern vftactivity">
        <Container>
          <Row>
            <Column span={12}>
              <h2>Clearing the Air: A Be Vape Free VFT Educator Guide</h2>
              <Resource
                image="img-vft-educator-guide-2.png"
                title="Virtual Field Trip Educator Guide"
                description={
                  <p>
                    The pre-field trip activities in this educator guide have
                    been designed to introduce students to the topics they will
                    learn about during the virtual field trip. The post-field
                    trip activities have been designed to connect and extend
                    student learning to classroom concepts.
                  </p>
                }
                actions={[
                  {
                    label: "Download Activity",
                    path: Educator_Guide,
                    type: "download",
                  },
                ]}
                horizontal
              />
            </Column>
          </Row>
        </Container>
      </Section>

      {/***************  CATCH MY BREATH CALLOUT ***************/}
      <Section className="lightgray vftboilerplate">
        <Container>
          <Row>
            <Column span={5}>
              <img
                src={TheCatchLogo}
                alt="The CATCH"
                className="vftboilerplate__image"
              />
            </Column>
            <Column span={7}>
              <p>
                Access the Core Program Bundle to complete the{" "}
                <strong>CATCH My Breath</strong> program. Using evidence-based
                and standards-aligned material, the Core Program Bundle teaches
                elementary, middle, and high school students the dangers of
                vaping.
              </p>
              <p>
                <a href="/educators" className="global__button">
                  Get Access
                </a>
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default VirtualFieldTripPage
